import { type RouteConfig } from 'vue-router'
import { moduleAllowed } from '/~/core/module-filters'
import { lazyEwalletRoute } from '/~/utils/lazy'
import { useVirtualCards } from '/~/composables/virtual-cards/use-virtual-cards'
const { isVirtualCardsEnabled } = useVirtualCards()

const routes: Array<RouteConfig> = [
  {
    path: '#profile-home',
    name: 'profile-home',
    component: () => import('./views/home/home.vue'),
    meta: {
      appear: null,
    },
  },
  {
    path: '#profile/withdraw-funds',
    name: 'profile/withdraw-funds',
    component: () => import('./views/withdraw/withdraw-funds.vue'),
  },
  {
    path: '#profile/withdraw-funds/add-payment-method',
    name: 'profile/withdraw-funds/add-bank-account',
    component: () => import('./views/withdraw/withdraw-add-bank-account.vue'),
  },
  {
    path: '#profile/withdraw-funds/confirmation/:id',
    name: 'profile/withdraw-funds/confirmation',
    component: () => import('./views/withdraw/withdraw-confirmation.vue'),
  },
  {
    path: '#profile-details',
    name: 'profile-details',
    component: () => import('./views/profile-settings/profile-home.vue'),
  },
  {
    path: '#profile-privacy-policy',
    name: 'profile-privacy-policy',
    component: () => import('./views/settings/settings-privacy-policy.vue'),
  },
  {
    path: '#profile-toc',
    name: 'profile-toc',
    component: () => import('./views/settings/settings-toc.vue'),
  },
  {
    path: '#profile-toc-single',
    name: 'profile-toc-single',
    component: () => import('./views/settings/settings-toc.vue'),
    props: {
      back: 'close',
    },
  },
  {
    path: '#profile-personal-details',
    name: 'profile-personal-details',
    component: () =>
      import('./views/profile-settings/profile-personal-details.vue'),
  },
  {
    path: '#profile-change-password',
    name: 'profile-change-password',
    component: () =>
      import(
        '/~/components/ewallet-menu/views/profile-settings/profile-change-password.vue'
      ),
  },
  {
    path: '#profile-edit-profile',
    name: 'profile-edit-profile',
    component: () =>
      import(
        '/~/components/ewallet-menu/views/profile-settings/profile-edit-profile-view.vue'
      ),
    props: {
      mobile: true,
    },
  },
  {
    path: '#profile-add-address/:id',
    name: 'profile-add-address',
    component: () =>
      import(
        '/~/components/ewallet-menu/views/profile-settings/profile-add-address.vue'
      ),
  },
  {
    path: '#profile-faq',
    name: 'profile-faq',
    component: () => import('./views/settings/settings-faq.vue'),
    props: {
      back: 'close',
    },
  },
  {
    path: '#profile-help',
    name: 'profile-help',
    component: () => import('./views/settings/settings-help.vue'),
  },
  {
    path: '#profile-help-confirmation',
    name: 'profile-help-confirmation',
    component: () => import('./views/settings/settings-help-confirmation.vue'),
  },
  {
    path: '#profile-saved',
    name: 'profile-saved',
    component: () => import('./views/saved/saved-home.vue'),
  },
  {
    path: '#profile-points',
    name: 'profile-points',
    component: () =>
      import('/~/components/ewallet-menu/views/points/points-home.vue'),
  },
  {
    path: '#profile-points-buy',
    name: 'profile-points-buy',
    component: () => import('./views/points/menu-points-buy.vue'),
  },
  {
    path: '#profile-points-pay-from',
    name: 'profile-points-pay-from',
    component: () => import('./views/points/menu-points-pay-from.vue'),
  },
  {
    path: '#profile-points-pay-from-add',
    name: 'profile-points-pay-from-add',
    component: () => import('./views/points/menu-points-pay-from-add.vue'),
  },
  {
    path: '#profile-points-transfer',
    name: 'profile-points-transfer',
    component: () => import('./views/points/menu-points-transfer.vue'),
  },
  {
    path: '#profile-points-partners-added',
    name: 'profile-points-partners-added',
    component: () =>
      import('./views/points/partners/menu-points-partners-added.vue'),
  },
  {
    path: '#profile-points-partners-all',
    name: 'profile-points-partners-all',
    component: () =>
      import('./views/points/partners/menu-points-partners-all.vue'),
  },
  {
    path: '#profile-points-partners-add',
    name: 'profile-points-partners-add',
    component: () =>
      import('./views/points/partners/menu-points-partners-add.vue'),
  },
  {
    path: '#profile-pay-qr',
    name: 'profile-pay-qr',
    component: () =>
      import('/~/components/ewallet-menu/views/scan/scan-pay-qr.vue'),
  },
  {
    path: '#profile-pay-confirmation',
    name: 'profile-pay-confirmation',
    component: () => import('./views/scan/scan-cash-in-confirmation.vue'),
  },
  {
    path: '#profile-payment-methods',
    name: 'profile-payment-methods',
    component: () => import('./views/payment-methods/payment-methods.vue'),
  },
  {
    path: '#profile-payment-methods-add',
    name: 'profile-payment-methods-add',
    component: () => import('./views/payment-methods/payment-methods-add.vue'),
  },
  {
    path: '#profile-photo-ids',
    name: 'profile-photo-ids',
    component: () => import('./views/photo-ids/photo-ids.vue'),
  },
  {
    path: '#profile-photo-id/:id',
    name: 'profile-photo-id',
    component: () =>
      import('/~/components/ewallet-menu/views/photo-ids/photo-id.vue'),
  },
  {
    path: '#profile-loyalty-memberships',
    name: 'profile-loyalty-memberships',
    component: () =>
      import('./views/loyalty-memberships/loyalty-memberships.vue'),
  },

  {
    path: '#profile-loyalty-memberships-brands',
    name: 'profile-loyalty-memberships-brands',
    component: () =>
      import('./views/loyalty-memberships/loyalty-memberships-brands.vue'),
  },

  {
    path: '#profile-loyalty-card-add/:id',
    name: 'profile-loyalty-card-add',
    component: () =>
      import('./views/loyalty-memberships/loyalty-memberships-create.vue'),
  },

  {
    path: '#profile-loyalty-card-details/:id',
    name: 'profile-loyalty-card-details',
    component: () =>
      import('./views/loyalty-memberships/loyalty-memberships-details.vue'),
  },

  {
    path: '#profile-loyalty-card-edit/:id',
    name: 'profile-loyalty-card-edit',
    component: () =>
      import('./views/loyalty-memberships/loyalty-memberships-edit.vue'),
  },

  {
    path: '#profile-e-purchases',
    name: 'profile-e-purchases',
    component: () => import('./views/e-purchases/e-purchases.vue'),
  },
  {
    path: '#profile-e-purchase/:id',
    name: 'profile-e-purchase',
    component: () => import('./views/e-purchases/e-purchase.vue'),
  },
  {
    path: '#profile-e-purchase-terms',
    name: 'profile-e-purchase-terms',
    component: () =>
      import(
        '/~/components/ewallet-menu/views/e-purchases/e-purchase-terms.vue'
      ),
    meta: {
      appear: 'bottom',
    },
  },
  {
    path: '#profile-scan',
    name: 'profile-scan',
    component: () =>
      import('/~/components/ewallet-menu/views/scan/scan-home.vue'),
  },
  {
    path: '#profile-scan-pay-from',
    name: 'profile-scan-pay-from',
    component: () =>
      import('/~/components/ewallet-menu/views/scan/scan-pay-from.vue'),
  },
  {
    path: '#profile-scan-pay-from-add',
    name: 'profile-scan-pay-from-add',
    component: () => import('./views/payment-methods/payment-methods-add.vue'),
  },
  {
    path: '#profile-pay-cash-in',
    name: 'profile-pay-cash-in',
    component: () => import('./views/scan/scan-cash-in.vue'),
  },
  {
    path: '#profile-cash-card',
    name: 'profile-cash-card',
    component: () =>
      import('/~/components/ewallet-menu/views/cash-card/cash-card.vue'),
  },
  {
    path: '#profile-add-cash',
    name: 'profile-add-cash',
    component: () =>
      import('/~/components/ewallet-menu/views/add-cash/add-cash-home.vue'),
  },
  {
    path: '#profile-add-cash-from',
    name: 'profile-add-cash-from',
    component: () =>
      import('/~/components/ewallet-menu/views/add-cash/add-cash-from.vue'),
  },
  {
    path: '#profile-add-cash-from-add',
    name: 'profile-add-cash-from-add',
    component: () => import('./views/payment-methods/payment-methods-add.vue'),
  },
  {
    path: '#profile-add-cash-confirmation',
    name: 'profile-add-cash-confirmation',
    component: () => import('./views/add-cash/menu-add-cash-confirmation.vue'),
    meta: {
      appear: 'bottom',
    },
  },
  {
    path: '#profile-third-party-user-agreement',
    name: 'third-party-user-agreement',
    component: () =>
      import(
        '/~/components/ewallet-menu/views/third-party-user/third-party-user-agreement.vue'
      ),
  },
  {
    path: '#profile-third-party-user-permissions',
    name: 'third-party-user-permissions',
    component: () =>
      import('./views/third-party-user/third-party-user-permissions.vue'),
  },
  {
    path: '#profile-third-party-user-add',
    name: 'third-party-user-add',
    component: () =>
      import('./views/third-party-user/third-party-user-add.vue'),
    props: true,
  },
  {
    path: '#profile-activity',
    name: 'profile-activity-list',
    component: () => import('./views/activity/activity-list.vue'),
  },
  {
    path: '#profile-activity/:id',
    name: 'profile-activity-details',
    component: () => import('./views/activity/activity-details.vue'),
  },
]

if (isVirtualCardsEnabled.value) {
  routes.push(
    {
      path: '#profile-add-virtual-card',
      name: 'profile-add-virtual-card',
      component: () =>
        import(
          '/~/components/ewallet-menu/views/virtual-card/add-virtual-card.vue'
        ),
    },
    {
      path: '#profile-create-virtual-card',
      name: 'profile-create-virtual-card',
      component: () =>
        import(
          '/~/components/ewallet-menu/views/virtual-card/create-virtual-card.vue'
        ),
    },
    {
      path: '#profile-virtual-card',
      name: 'profile-virtual-card',
      component: () =>
        import(
          '/~/components/ewallet-menu/views/virtual-card/virtual-card.vue'
        ),
    }
  )
}

export function getMatchingRoute(routes: Array<RouteConfig>, hash: string) {
  let props: Record<string, string> | undefined

  let menuRoute = routes.find((route) => {
    const parts = route.path.split('/')
    const regex = new RegExp(
      '^' +
        parts
          .map((part) =>
            part.startsWith(':')
              ? `(?:/(?<${part.substr(1)}>[a-zA-Z0-9_-]*))?`
              : `/${part}`
          )
          .join('')
          .substr(1) +
        '$',
      'gi'
    )

    const result = regex.exec(hash)

    props = result?.groups

    return result
  })

  if (!menuRoute) {
    const path = hash.split('/')?.[0]

    menuRoute = routes.find((route) => route.path === path)
  }

  if (!menuRoute) {
    return null
  }

  const menuRouteProps =
    typeof menuRoute.props === 'object' ? menuRoute.props : undefined

  return {
    ...menuRoute,
    props: {
      ...menuRouteProps,
      ...props,
    },
  }
}

export default routes.map((route) => {
  if ('component' in route) {
    const routeProps = typeof route.props === 'object' ? route.props : undefined

    return {
      ...route,
      component: lazyEwalletRoute(route.component),
      meta: {
        appear: 'right',
        ...route.meta,
      },
      props: {
        to: 'menu-modal',
        ...routeProps,
      },
    }
  }
})
